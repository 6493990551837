import { Component, Input, OnInit, effect } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public isNavbarCollapsed = true;
  public isClientPage = true;
  @Input() isLoggedIn = false;

  constructor(
    private router: Router,
    public userService: UserService
  ) {

    // effect(() => {
    //   console.log(this.userService.isLoggedIn());

    // })

  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
    this.isClientPage = !window.location.href.includes('admin');
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isClientPage = !event.url.includes('admin');
      });
  }

  login() {
    this.router.navigateByUrl('/login');
    // this.userService.isLoggedIn.update(value => !value);
  }

  logout() {
    this.router.navigateByUrl('/');
    // this.userService.isLoggedIn.next(false);
    // this.userService.isLoggedIn.update(value => !value);
  }
}
