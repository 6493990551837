import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { Subject, filter, takeUntil } from "rxjs";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "app-client-header",
  templateUrl: "./client-header.component.html",
  styleUrls: ["./client-header.component.scss"],
})
export class ClientHeaderComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  navbarOpen = false;
  isLoggedIn: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public userService: UserService,
    private router: Router,
    private apiService: ApiService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.userService.isLoggedIn
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isLoggedIn = value;
      });
  }

  ngAfterViewInit(): void {
    const listItems = document.querySelectorAll('.menu-item');
    listItems.forEach((item) => {
      this.renderer.setStyle(item, "width", `${item.clientWidth + 10}px`)
    });
  }

  ngAfterContentInit(): void {
    this.setActiveMenu(this.router.url);
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .pipe(filter((value) => value instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setActiveMenu(event.url);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  private setActiveMenu(url: string) {
    removeActiveClass();
    let items = Array.from(document.querySelectorAll(".menu-item"));
    if (items.length > 6) {
      items = items.slice(6);
    }
    if (url.includes("/tutorials")) {
      items[0].classList.add("active");
    } else if (url.includes("/licensing")) {
      items[1].classList.add("active");
    } else if (url.includes("/checking")) {
      items[2].classList.add("active");
    } else if (url.includes("/business")) {
      items[3].classList.add("active");
    } else if (url.includes("/premium")) {
      items[4].classList.add("active");
    } else if (url.includes("my-page")) {
      items[5]?.classList?.add("active");
    }

    function removeActiveClass() {
      const items = Array.from(document.querySelectorAll("li"));
      for (const item of items) {
        item.classList.remove("active");
      }
    }
  }

  protected login() {
    this.apiService.goToDiscordLoginPage();
  }

  protected logout() {
    this.apiService.logout();
  }
}
