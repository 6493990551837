import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    // {
    //   title: "대시보드",
    //   icon: "home",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   badgeValue: "2",
    //   active: true,
    //   children: [
    //     { path: "/simple-page/first-page", title: "First Page", type: "link" },
    //     { path: "/simple-page/second-page", title: "Second Page", type: "link" },
    //   ],
    // },
    {
      headTitle1: "대시보드",
    },
    { path: "/admin/dashboard", icon: "home", title: "대시보드", type: "link", bookmark: false },
    { path: "/admin/statistic", icon: "search", title: "통계", type: "link", bookmark: false },
    {
      headTitle1: "컨텐츠 관리",
    },
    { path: "/admin/community-streamer-settings", icon: "settings", title: "커뮤니티 및 스트리머 관리", type: "link", bookmark: false },
    { path: "/admin/register-settings", icon: "settings", title: "페이지 설명 이미지 등록", type: "link", bookmark: false },
    { path: "/admin/tos-settings", icon: "settings", title: "ToS 텍스트 등록", type: "link", bookmark: false },
    { path: "/admin/privacy-policy-settings", icon: "settings", title: "Privacy Policy 텍스트 등록", type: "link", bookmark: false },
    {
      headTitle1: "사용자 관리",
    },
    { path: "/admin/user-management", icon: "user", title: "사용자 관리", type: "link", bookmark: false },
    { path: "/admin/license-management", icon: "database", title: "License 관리", type: "link", bookmark: false },
    { path: "/admin/premium-management", icon: "database", title: "Premium 관리", type: "link", bookmark: false },
    { path: "/admin/report-management", icon: "database", title: "신고 관리", type: "link", bookmark: false },
    {
      headTitle1: "DB 관리",
    },
    { path: "/admin/blocked-user-management", icon: "user", title: "차단 인원 관리", type: "link", bookmark: false },
    { path: "/admin/blocked-community-management", icon: "database", title: "차단 커뮤니티 관리", type: "link", bookmark: false },
    { path: "/admin/community-management", icon: "database", title: "커뮤니티 관리", type: "link", bookmark: false },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
