<swiper direction="vertical" #homeSwiper class="homeSlider" [mousewheel]="true" (wheel)="onWheel($event)"
  (slideChange)="onSlideChange($event)">
  <ng-template swiperSlide style="height: 100vh;" class="d-flex flex-column justify-content-center">
    <app-client-header></app-client-header>
    <div class="hero text-center">
      <div class="hero-content row">
        <div class="col-md-6">
          <h1 class="slogan">Protect Your Community from Threats</h1>
          <p class="sub-slogan">InFi-EYE Blocks Accounts that Distribute Threatening Illegal Contents</p>
          <a href="https://discord.com/api/oauth2/authorize?client_id=775723094257303552&permissions=8&scope=bot%20applications.commands"
            class="cta-button">
            <span class="cta-label">Invite InFi-EYE</span>
          </a>
        </div>
        <div class="col-md-6">
          <img src="../../../../assets/images/hero_image.png" width="400" alt="">
        </div>
      </div>
      <div class="row information" style="margin-top: 150px; margin-left: auto; margin-right: auto; padding-bottom: 0;">
        <div class="col-md-6">
          <div class="col-6">
            <p>Used by&nbsp;<span>{{ communitiesCount | number }}</span>&nbsp;Communities · Streamers</p>
          </div>
          <div class="col-6">
            <p>Accounts blocked so far:&nbsp;<span>{{ bansCount | number }}</span>&nbsp;{{ bansCount == 1 ? 'Account' :
              'Accounts'}}</p>
          </div>
        </div>
        <div class="col-md-6 stats-column">
          <div class="swiper-container">
            <swiper #swiper [slidesPerView]="5" class="my-swiper"
              [navigation]="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
              (slideChange)="slideChanged($event)">
              <ng-template swiperSlide *ngFor="let streamer of streamers; let i = index">
                <div class="d-flex flex-column align-items-center pointer">
                  <img [src]="streamer.profileImage" [alt]="" class="image">
                  <span>{{ streamer.name }}</span>
                </div>
              </ng-template>
            </swiper>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template swiperSlide style="height: 100vh;" class="d-flex align-items-center justify-content-center">
    <div class="news-wrapper">
      <div class="news">
        <h1>
          News
        </h1>
        <div class="row">
          <div class="col-md-3">
            <div class="news-header" style="background-image: url(../../../../assets/images/news-1.png);"></div>
            <div class="news-body">
              <p>Title</p>
              <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, qui? Asperiores necessitatibus culpa
                quasi iste totam ipsam tenetur earum.</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="news-header" style="background-image: url(../../../../assets/images/news-2.png);"></div>
            <div class="news-body">
              <p>Title</p>
              <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, qui? Asperiores necessitatibus culpa
                quasi iste totam ipsam tenetur earum.</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="news-header" style="background-image: url(../../../../assets/images/news-3.png);"></div>
            <div class="news-body">
              <p>Title</p>
              <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, qui? Asperiores necessitatibus culpa
                quasi iste totam ipsam tenetur earum.</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="news-header" style="background-image: url(../../../../assets/images/news-4.png);"></div>
            <div class="news-body">
              <p>Title</p>
              <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, qui? Asperiores necessitatibus culpa
                quasi iste totam ipsam tenetur earum.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template swiperSlide style="height: 100vh;" class="d-flex flex-column justify-content-between">
    <div class="feature-wrapper">
      <div class="row features" style="margin-bottom: 12rem;">
        <h1>About</h1>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_00.png" width="50" alt="">
          <p class="feature-text">Blocking Illegal Users</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_01.png" width="50" alt="">
          <p class="feature-text">Continuous Detection of <br> Illegal Users and Communities</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_02.png" width="50" alt="">
          <p class="feature-text">Community Log</p>
        </div>
        <div class="vertical-divider"></div>
        <div class="col-lg-3 col-6 feature">
          <img src="../../../../assets/images/ic_02.png" width="50" alt="">
          <p class="feature-text">InFi-EYE License a <br> Clean User System</p>
        </div>
      </div>
      <app-client-footer></app-client-footer>
    </div>
  </ng-template>
</swiper>
