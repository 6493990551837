import { Routes } from '@angular/router';
import { LoginComponent } from 'src/app/pages/admin/auth/login/login.component';

export const full: Routes = [
    {
        path: "admin/login",
        component: LoginComponent
    },
    {
        path: "",
        loadChildren: () => import("../../pages/client/client.module").then((m) => m.ClientModule),
    },
];
