import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, firstValueFrom, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tutorial } from '../models/tutorial-model';
import { Report } from '../models/report-model';
import { CookieService } from 'ngx-cookie-service';
import { PaymentType } from '../data/enums/payment';
import { UserService } from './user.service';
import { AdminService } from './admin.service';
import { ContentCategory } from '../data/enums/content-category';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private readonly redirectionUrl = 'https://infi-eye.com/home';

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private userService: UserService,
        private adminService: AdminService,
        private router: Router,
    ) {}

    goToDiscordLoginPage(): void {
        const url = `https://discord.com/oauth2/authorize?client_id=${environment.discordClientId}&response_type=code&redirect_uri=${this.redirectionUrl}&scope=guilds+identify+email`;
        window.open(url, '_self');
    }

    login(code: string): Observable<boolean> {
        const body = {
            code: code,
            redirect_uri: this.redirectionUrl,
        };
        return this.http
            .post<{
                access_token: string;
                expires_in: 604800;
                refresh_token: string;
                message: string;
            }>(`${environment.infiEyeApi}/api/v1/auth/login`, body)
            .pipe(
                map((value) => {
                    if (value.access_token.length && value.expires_in) {
                        const expiresIn = value.expires_in / 60 / 60 / 24;
                        this.cookieService.set('access_token', value.access_token, expiresIn);
                        this.cookieService.set('refresh_token', value.refresh_token, expiresIn);
                        this.userService.setLoginStatus(true);
                        return true;
                    } else {
                        return false;
                    }
                }),
            );
    }

    logout(): void {
        this.cookieService.delete('access_token');
        this.cookieService.delete('refresh_token');
        this.cookieService.delete('user_id');
        this.cookieService.delete('user_permissions');
        this.cookieService.delete('username');
        this.cookieService.delete('discord_id');
        this.userService.setLoginStatus(false);
        this.router.navigate(['/home']);
    }

    /**
     * @author rmchoi
     * @returns data for main page info
     */
    getMainPageData(): Observable<{
        bansCount: number;
        communitiesCount: number;
        streamers: { name: string; profileImage: string }[];
    }> {
        return this.http.get(`${environment.api}/api/main`, { withCredentials: true }).pipe(
            map((res) => {
                return {
                    bansCount: Number.parseInt(res['totalBanCount']),
                    communitiesCount: Number.parseInt(res['usingCommunities']),
                    streamers: (res['streamer'] as any[]).map((value) => {
                        return {
                            name: value['name'],
                            profileImage: value['profilePath'],
                        };
                    }),
                };
            }),
        );
    }

    /**
     * @author rmchoi
     * @returns Tutorial object
     */
    getTutorials(): Observable<Tutorial> {
        return this.http.get(`${environment.api}/api/tutorial`).pipe(
            map((res: any) => {
                const result: Tutorial = {
                    howToUse: res.content[0].image,
                    commands: res.content[1].image,
                    codes: res.content[2].image,
                };
                return result;
            }),
        );
    }

    /**
     * @author rmchoi
     * @returns array of categories
     */
    getBusinessCategories(): Observable<string[]> {
        return this.http.get(`${environment.api}/api/businessCategory`).pipe(
            map((res: any) => {
                return (res.content as any[]).map((value) => value.category);
            }),
        );
    }

    /**
     *
     * @param title
     * @param contents
     * @param category
     * @param files
     * @param contact
     * @param email
     * @returns boolean flag whether request was successfull or not
     */
    createBusinessInquiry(
        title: string,
        contents: string,
        category: string,
        files: File[],
        contact: string,
        email: string,
    ): Observable<boolean> {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        formData.append('title', title);
        formData.append('contents', contents);
        formData.append('category', category);
        formData.append('contact', contact);
        formData.append('email', email);

        return this.http.post(`${environment.api}/api/business/send`, formData).pipe(
            map((res: any) => {
                if (res.status === 'success') {
                    return true;
                } else {
                    return false;
                }
            }),
        );
    }

    /**
     *
     * @param accountId discord account ID
     * @returns
     */
    checkDiscordAccount(accountId: string): Observable<{ message: string; bannedAt: Date | null }> {
        return this.http.get(`${environment.api}/api/check/${accountId}`).pipe(
            map((res: any) => {
                let date = null;
                if (res.bannedAt) {
                    date = new Date(res.bannedAt);
                }
                return {
                    message: res.msg,
                    bannedAt: date,
                };
            }),
        );
    }

    /**
     * @author rmchoi
     * @returns report management number and its report items
     */
    getReports(): Observable<Report> {
        return this.http.get(`${environment.api}/api/report`).pipe(
            map((res) => {
                console.log(res);
                const result: Report = {
                    reportManagementNumber: res['report management number'],
                    reportItems: (res['reportItems'] as any[]).map((value) => value.item),
                };
                return result;
            }),
        );
    }

    /**
     *
     * @param uid reported UID
     * @param item item ID
     * @param content report details
     * @param files attached files
     * @param mid report management number
     * @returns
     */
    sendReport(uid: string, item: string, content: string, files: File[], mid: string): Observable<boolean> {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        formData.append('uid', uid);
        formData.append('item', item);
        formData.append('content', content);
        formData.append('mid', mid);

        return this.http
            .post(`${environment.api}/api/report/send`, formData, {
                withCredentials: true,
            })
            .pipe(
                map((res: any) => {
                    if (res.status === 'success') {
                        return true;
                    } else {
                        return false;
                    }
                }),
            );
    }

    /**
     * @author rmchoi
     * @description redirect user to paypal payment link
     */
    getPaypalPaymentLink(planID: number, type: PaymentType): void {
        this.http
            .get(`${environment.api}/api/payment/paypal`, {
                params: {
                    productCode: planID,
                    type: type,
                },
                withCredentials: true,
            })
            .subscribe((res) => {
                if (res['status'] === 'success') {
                    window.open(res['url'], '_self');
                } else {
                    throw new Error('Error occured while generating link');
                }
            });
    }

    /**
     * @author rmchoi
     * @param paymentId data recieved after successful paypal payment
     * @param payerId data recieved after successful paypal payment
     */
    sendPaymentDataToServer(paymentId: string, payerId: string): Observable<any> {
        return this.http
            .get(`${environment.api}/api/payment/paypal/pay/success`, {
                params: { paymentId: paymentId, PayerID: payerId },
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    console.log(res);
                    return res;
                }),
            );
    }

    // TODO: refactor once API is ready
    verifyAccount(uid: string): Observable<boolean> {
        return new Observable((subscriber) => {
            setTimeout(() => {
                subscriber.next(Math.floor(Math.random() * 2) === 0 ? false : true);
                subscriber.complete();
            }, 2000);
        });
    }

    // TODO: refactor once API is ready
    // POST https://infi-eye.com/api/v1/users/license/@me => API?
    notifyAdWatchFinished(): Observable<boolean> {
        return new Observable((subscriber) => {
            setTimeout(() => {
                subscriber.next(true);
                subscriber.complete();
            }, 1000);
        });
    }

    getMyReportHistory(): Observable<{ date: Date; status: string; id: string }[]> {
        return this.http
            .get<{
                status: string;
                reportHistory: {
                    content: {
                        mid: string;
                        regDatetime: string;
                        reportId: string;
                        status: number;
                    }[];
                    total: number;
                };
            }>(`${environment.api}/api/mypage/reportHistory`, {
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    return res.reportHistory.content.map((item) => {
                        let status: string;
                        switch (item.status) {
                            case 0:
                                status = '접수';
                                break;
                            case 1:
                                status = '반려';
                                break;
                            case 2:
                                status = '승인';
                                break;
                            case 3:
                                status = '처리중';
                                break;
                        }
                        return {
                            date: new Date(item.regDatetime.replace(' ', 'T')),
                            status: status,
                            id: item.mid,
                        };
                    });
                }),
            );
    }

    getUnblockRequestHistory(): Observable<any> {
        return this.http
            .get<{
                status: string;
                unblockRequestHistory: {
                    content: string;
                    item: string;
                    regDatetime: string;
                    status: number;
                };
            }>(`${environment.api}/api/mypage/unblockRequestHistory`, {
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    let status: string;
                    switch (res.unblockRequestHistory.status) {
                        case 0:
                            status = '접수';
                            break;
                        case 1:
                            status = '반려';
                            break;
                        case 2:
                            status = '승인';
                            break;
                        case 3:
                            status = '처리중';
                            break;
                    }
                    return {
                        title: res.unblockRequestHistory.item,
                        content: res.unblockRequestHistory.content,
                        status: status,
                    };
                }),
            );
    }

    getReportDetails(reportId: string): Observable<{
        id: string;
        title: string;
        content: string;
        files: string[];
    }> {
        let params = new HttpParams();
        params = params.append('mid', reportId);
        return this.http
            .get<{
                status: string;
                reportHistoryDetail: {
                    file_url: string[];
                    item: string;
                    target_id: string;
                    content: string;
                }[];
            }>(`${environment.api}/api/mypage/reportHistoryDetail`, {
                params: params,
                withCredentials: true,
            })
            .pipe(
                map((res) => {
                    return {
                        id: res.reportHistoryDetail[0].target_id,
                        title: res.reportHistoryDetail[0].item,
                        content: res.reportHistoryDetail[0].content,
                        files: res.reportHistoryDetail[0].file_url.map((value) => value.split('report/')[1]),
                    };
                }),
            );
    }

    // getMyLicense(): Observable<any> {
    //   let headers = new HttpHeaders();
    //   // let params = new HttpParams();
    //   const cookies = document.cookie.split(";");
    //   const accessToken = cookies.find((cookie) =>
    //     cookie.includes("access_token")
    // );
    // if (accessToken) {
    //   // params = params.append('authorization', `Basic ${accessToken.split("=")[1]}`);
    //   headers = headers.append(
    //       "Authorization",
    //       `Basic ${accessToken.split("=")[1]}`
    //     );
    //     return this.http
    //       .post(
    //         `${environment.infiEyeApi}/api/v1/users/@me/license`,
    //         {},
    //         {
    //           // params: params,
    //           headers: headers,
    //         }
    //       )
    //       .pipe(
    //         map((res) => {
    //           console.log(res);
    //           return res;
    //         })
    //       );
    //   } else {
    //     throw new Error("Authorization required");
    //   }
    // }

    async getPremiumSettings(): Promise<
        {
            id: string;
            name: string;
            icon: string | null;
            owner: boolean;
            isActive: boolean;
            // settings: { enabled: string; warning: string };
            codes: { id: string; status: number }[] | undefined;
        }[]
    > {
        let headers = new HttpHeaders();
        const cookies = document.cookie.split(';');
        const accessToken = cookies.find((cookie) => cookie.includes('access_token'));
        if (accessToken) {
            headers = headers.append('Authorization', `Basic ${accessToken.split('=')[1]}`);
            let codes = await firstValueFrom(this.adminService.getContent(ContentCategory.codes));
            let codesList = codes
                .split('<tbody>')[1]
                .split('<tr>')
                .map((value) => value.split('</td><td>')[0].split('<td>')[1])
                .filter((value) => value && value.length);
            let response = await firstValueFrom(
                this.http.get<{
                    actived:
                        | {
                              id: string;
                              icon: string | null;
                              name: string;
                              owner: boolean;
                              activated_by: string;
                              discord: {
                                  id: string;
                                  name: string;
                                  icon: string | null;
                                  owner: boolean;
                                  permissions: string;
                                  features: [];
                              };
                              settings: { enabled: string; warning: string };
                          }[];
                    unActived:
                        | {
                              id: string;
                              icon: string | null;
                              name: string;
                              owner: boolean;
                              permissions: string;
                              features: any[];
                          }[];
                }>(`${environment.infiEyeApi}/api/v1/users/@me/communities`, {
                    headers: headers,
                }),
            );
            let communities = [
                ...response.actived.map((value) => {
                    return {
                        ...value,
                        isActive: true,
                        name: value.discord.name,
                        icon: value.discord.icon,
                        codes: [],
                    };
                }),
                ...response.unActived.map((value) => {
                    return {
                        ...value,
                        isActive: false,
                        owner: value.owner,
                        codes: [],
                        settings: null,
                    };
                }),
            ];
            communities.map((value) => {
                if (value.isActive) {
                    if (value.settings.enabled === '4096') {
                        if (value.codes) {
                            value.codes = codesList.map((value) => {
                                return {
                                    id: value,
                                    status: 2,
                                };
                            });
                        }
                    } else {
                        if (value.codes) {
                            const enabledCodes = value.settings.enabled.replace(/\NIE_/g, '').split(' | ');
                            const warningCodes = value.settings.warning.replace(/\NIE_/g, '').split(' | ');
                            value.codes = codesList.map((value) => {
                                return {
                                    id: value,
                                    status: enabledCodes.includes(value) ? 2 : warningCodes.includes(value) ? 1 : 0,
                                };
                            });
                        }
                    }
                }
            });
            return communities;
        } else {
            throw new Error('Authorization required');
        }
    }

    updatePremiumSettings(id: string, settings: { enabled: string; warning: string }) {
        let headers = new HttpHeaders();
        const cookies = document.cookie.split(';');
        const accessToken = cookies.find((cookie) => cookie.includes('access_token'));
        if (accessToken) {
            headers = headers.append('Authorization', `Basic ${accessToken.split('=')[1]}`);
            return this.http
                .patch(
                    `${environment.infiEyeApi}/api/v1/communities/${id}`,
                    { settings: settings },
                    {
                        headers: headers,
                    },
                )
                .pipe(
                    map((res) => {
                        console.log(res);
                        return res;
                    }),
                );
        } else {
            throw new Error('Authorization required');
        }
    }

    changeServerStatus(communityId: string, currentStatus: 'active' | 'inactive'): Observable<boolean> {
        let headers = new HttpHeaders();
        const cookies = document.cookie.split(';');
        const accessToken = cookies.find((cookie) => cookie.includes('access_token'));
        if (accessToken) {
            headers = headers.append('Authorization', `Basic ${accessToken.split('=')[1]}`);
            switch (currentStatus) {
                case 'active':
                    return this.http
                        .delete(`${environment.infiEyeApi}/api/v1/communities/${communityId}`, {
                            headers: headers,
                            observe: 'response',
                        })
                        .pipe(
                            map((res) => {
                                return res.status === 200;
                            }),
                        );
                case 'inactive':
                    return this.http
                        .post(
                            `${environment.infiEyeApi}/api/v1/communities/${communityId}`,
                            {},
                            {
                                headers: headers,
                                observe: 'response',
                            },
                        )
                        .pipe(
                            map((res) => {
                                return res.status === 200;
                            }),
                        );
            }
        } else {
            throw new Error('Authorization required');
        }
    }

    checkMyAccountForBan(): Observable<{ code: string; expires: Date; message: string } | false | null> {
        let headers = new HttpHeaders();
        const cookies = document.cookie.split(';');
        const accessToken = cookies.find((cookie) => cookie.includes('access_token'));
        if (accessToken) {
            headers = headers.append('Authorization', `Basic ${accessToken.split('=')[1]}`);
            return this.http
                .get<
                    [
                        {
                            id: string;
                            user_id: string;
                            guild_id: string;
                            code: string;
                            banned_by: string;
                            attachment: {
                                id: string;
                                name: string;
                                url: string;
                            };
                            unban: {
                                unbanned_at: string;
                                unbanned_by: string;
                                reason: string;
                            };
                            expires: string;
                        },
                    ]
                >(`${environment.infiEyeApi}/api/v1/users/@me/bans`, {
                    headers: headers,
                })
                .pipe(
                    catchError((err) => {
                        if (err.status === 404) {
                            return of(undefined);
                        } else if (err.status === 500) {
                            return of(null);
                        }
                    }),
                    map((res) => {
                        console.log(res);
                        if (res === null) {
                            return null;
                        } else if (res === undefined) {
                            return false;
                        } else {
                            return {
                                code: res[0].code,
                                expires: new Date(res[0].expires),
                                message: '',
                            };
                        }
                    }),
                );
        } else {
            throw new Error('Authorization required');
        }
    }

    checkAccountForBans(discordId: string): Observable<
        | {
              id: string;
              userId: string;
              code: string;
              expires: Date;
              message: string;
          }
        | false
        | null
    > {
        let headers = new HttpHeaders();
        const cookies = document.cookie.split(';');
        const accessToken = cookies.find((cookie) => cookie.includes('access_token'));
        if (accessToken) {
            headers = headers.append('Authorization', `Basic ${accessToken.split('=')[1]}`);
            return this.http
                .get<
                    [
                        {
                            id: string;
                            user_id: string;
                            guild_id: string;
                            code: string;
                            banned_by: string;
                            attachment: {
                                id: string;
                                name: string;
                                url: string;
                            };
                            unban: {
                                unbanned_at: string;
                                unbanned_by: string;
                                reason: string;
                            };
                            expires: string;
                        },
                    ]
                >(`${environment.infiEyeApi}/api/v1/bans/discord_id/${discordId}`, {
                    headers: headers,
                })
                .pipe(
                    catchError((err) => {
                        if (err.status === 404) {
                            return of(undefined);
                        } else if (err.status === 500) {
                            return of(null);
                        }
                    }),
                    map((res) => {
                        if (res === null) {
                            return null;
                        } else if (res === undefined) {
                            return false;
                        } else {
                            return {
                                id: res[0].id,
                                userId: res[0].user_id,
                                code: res[0].code,
                                expires: new Date(res[0].expires),
                                message: '',
                            };
                        }
                    }),
                );
        } else {
            throw new Error('Authorization required');
        }
    }

    createUnblockRequest(unblockItem: string, content: string, files: File[]): Observable<any> {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        formData.append('item', unblockItem);
        formData.append('content', content);
        return this.http
            .post(`${environment.api}/api/unblock/send`, formData, {
                withCredentials: true,
            })
            .pipe(
                map((res: any) => {
                    console.log(res);
                    if (res.status === 'success') {
                        return true;
                    } else {
                        return false;
                    }
                }),
            );
    }
}
