import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";

// Services
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { ClientHeaderComponent } from "./components/client-header/client-header.component";
import { ClientFooterComponent } from "./components/client-footer/client-footer.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { NgApexchartsModule } from "ng-apexcharts";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { defineLocale, koLocale } from "ngx-bootstrap/chronos";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { LoadingComponent } from "./components/loading/loading.component";
import { SearchByKeywordPipe } from "./pipes/search-by-keyword.pipe";
import { SelectedCodesPipe } from "./pipes/selected-codes.pipe";
import { NoSearchResultsComponent } from "./components/no-search-results/no-search-results.component";
import { CodeToStringPipe } from "./pipes/code-to-string.pipe";
import { CustomPaginationPipe } from "./pipes/custom-pagination.pipe";

defineLocale("kr", koLocale);

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    NotificationComponent,
    MyAccountComponent,
    SvgIconComponent,
    PaginationComponent,
    ClientHeaderComponent,
    ClientFooterComponent,
    LoadingComponent,
    SearchByKeywordPipe,
    SelectedCodesPipe,
    NoSearchResultsComponent,
    CodeToStringPipe,
    CustomPaginationPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forRoot(),
    CarouselModule,
    SwiperModule,
    NgbDropdownModule,
    BsDatepickerModule.forRoot(),
    CKEditorModule,
  ],
  providers: [DecimalPipe],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    SvgIconComponent,
    PaginationComponent,
    ClientHeaderComponent,
    ClientFooterComponent,
    SwiperModule,
    NgApexchartsModule,
    BsDatepickerModule,
    CKEditorModule,
    LoadingComponent,
    SearchByKeywordPipe,
    SelectedCodesPipe,
    NoSearchResultsComponent,
    CodeToStringPipe,
    CustomPaginationPipe,
  ],
})
export class SharedModule {}
