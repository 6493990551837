import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedCodes'
})
export class SelectedCodesPipe implements PipeTransform {

  transform(value: any[], ...args: unknown[]): string {
    return value.filter((item) => item.isSelected).join(', ');
  }
}
