<div class="footer text-white ">
  <div class="container">
    <div class="row footer-content">
      <div class="col-md-6 col-12 footer-column">
        <img src="../../../../../assets/images/logo/logo_dark.png" alt="InFi-EYE Logo" class="footer-logo">
        <div>
          <span class="footer-text">주식회사 알테르모</span>
        </div>
        <div>
          <span class="footer-text">사업자등록번호 : 806-88-02952 | 통신판매업신고번호 : 2024-경기김포-2203</span>
        </div>
        <div>
          <span class="footer-text">대표자명 : 석준현 | 전화번호 : 031-991-3990</span>
        </div>
        <div>
          <span class="footer-text">주소 : 경기도 김포시 김포한강10로133번길 107 디원시티 시그니처 804호</span>
        </div>
        <p class="footer-text mt-2">ⓒ2023 Altermo Co., Ltd. All Rights Reserved.</p>
      </div>
      <div class="col-md-6 col-12 d-flex flex-column justify-content-center align-items-end footer-column">
        <a href="#" class="footer-link" routerLink="/privacy-policy">Privacy Policy</a>
        <a href="#" class="footer-link" routerLink="/term-of-service">Terms Of Service</a>
      </div>
    </div>
  </div>
</div>