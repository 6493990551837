import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customPagination",
})
export class CustomPaginationPipe implements PipeTransform {
  transform(value: any[], page: number, pageSize: number): any[] {
    const index = page - 1;
    return value.slice(index * pageSize, index * pageSize + pageSize);
  }
}
