import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "admin",
    loadChildren: () => import("../../pages/admin/admin.module").then((m) => m.AdminModule),
  },
  // {
  //   path: "admin/dashboard",
  //   loadChildren: () => import("../../pages/admin/dashboard/dashboard/dashboard.module").then((m) => m.DashboardModule),
  // },
  // {
  //   path: "admin/statistic",
  //   loadChildren: () => import("../../pages/admin/dashboard/stats/stats.module").then((m) => m.StatsModule),
  // },
  // {
  //   path: "admin/community-streamer-settings",
  //   loadChildren: () => import("../../pages/admin/content-management/community-streamer/community-streamer.module").then((m) => m.CommunityStreamerModule),
  // },
  // {
  //   path: "admin/register-settings",
  //   loadChildren: () => import("../../pages/admin/content-management/register-desc/register-desc.module").then((m) => m.RegisterDescModule),
  // },
  // {
  //   path: "admin/tos-settings",
  //   loadChildren: () => import("../../pages/admin/content-management/tos-desc/tos-desc.module").then((m) => m.TosDescModule),
  // },
  // {
  //   path: "admin/privacy-policy-settings",
  //   loadChildren: () => import("../../pages/admin/content-management/privacy-policy-desc/privacy-policy-desc.module").then((m) => m.PrivacyPolicyDescModule),
  // },
  // {
  //   path: "admin/user-management",
  //   loadChildren: () => import("../../pages/admin/user-management/user-management/user-management.module").then((m) => m.UserManagementModule),
  // },
  // {
  //   path: "admin/license-management",
  //   loadChildren: () => import("../../pages/admin/user-management/license-management/license-management.module").then((m) => m.LicenseManagementModule),
  // },
  // {
  //   path: "admin/premium-management",
  //   loadChildren: () => import("../../pages/admin/user-management/premium-management/premium-management.module").then((m) => m.PremiumManagementModule),
  // },
  // {
  //   path: "admin/blocked-user-management",
  //   loadChildren: () => import("../../pages/admin/db-management/blocked-user-management/blocked-user-management.module").then((m) => m.BlockedUserManagementModule),
  // },
  // {
  //   path: "admin/blocked-community-management",
  //   loadChildren: () => import("../../pages/admin/db-management/blocked-community-management/blocked-community-management.module").then((m) => m.BlockedCommunityManagementModule),
  // },
  // {
  //   path: "admin/community-management",
  //   loadChildren: () => import("../../pages/admin/db-management/community-management/community-management.module").then((m) => m.CommunityManagementModule),
  // },
];
