import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LayoutService } from "../../../services/layout.service";
import { NavService } from "../../../services/nav.service";
import { fadeInAnimation } from "../../../data/router-animation/router-animation";
import { Subject, takeUntil } from "rxjs";
import * as feather from "feather-icons";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  // animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    public navServices: NavService,
    public layout: LayoutService
  ) {
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      this.layout.config.settings.layout = params.layout
        ? params.layout
        : this.layout.config.settings.layout;
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }

  get layoutClass() {
    return "compact-wrapper";
  }

  ngOnInit() {}
}
