// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api: 'http://api.bory.io:8084',
  infiEyeApi: `https://infi-eye.com`,
  tossPaymentClientKey: 'test_ck_P9BRQmyarY7A2Eaa5zpv3J07KzLN',
  discordClientId: '1165945807078428744',
  firebaseConfig: {
    apiKey: "AIzaSyBJPegp1VqYG-AVRhE3PNlFPAMD_nKWX90",
    authDomain: "infi-eye.firebaseapp.com",
    projectId: "infi-eye",
    storageBucket: "infi-eye.appspot.com",
    messagingSenderId: "239294951722",
    appId: "1:239294951722:web:6a6fa04e08ebddb03fee53"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
