<nav class="navbar navbar-expand-lg navbar-dark justify-content-between" id="top-header">
  <div id="logo" class="pointer" routerLink="/">
    <img src="../../../../../assets/images/logo/img_logo_gnb.svg" width="140" height="45" alt="InFi-EYE Logo">
  </div>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse px-3" [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav mr-auto justify-content-end" style="width: 100%;">
      <li class="menu-item mx-2">
        <a class="pointer" routerLink="/tutorials">
          Tutorials
        </a>
      </li>
      <li class="menu-item mx-2">
        <a class="pointer" routerLink="/licensing">InFi-EYE License</a>
      </li>
      <li class="menu-item mx-2">
        <a class="pointer" routerLink="/checking">Checking</a>
      </li>
      <li class="menu-item mx-2">
        <a class="pointer" routerLink="/business">Business</a>
      </li>
      <li class="menu-item mx-2">
        <a class="pointer" style="color: #ffd200;" routerLink="/premium" [queryParams]="{ step: 1 }">
          Premium</a>
      </li>
      <li *ngIf="isLoggedIn" class="mx-2 d-flex">
        <a class="login-button" style="text-align: center; margin-top: auto; margin-bottom: auto; font-weight: 500;"
          routerLink="/my-page" fragment="purchase-history">My Page</a>
      </li>
    </ul>
    <ng-container *ngIf="!isLoggedIn">
      <button id="login-btn" class="ms-3 login-button" (click)="login()">Login</button>
    </ng-container>
  </div>
</nav>