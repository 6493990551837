import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { ApiService } from "src/app/shared/services/api.service";
import { UserService } from "src/app/shared/services/user.service";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from "swiper";
import { SwiperComponent } from "swiper/angular";
// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobile = window.innerWidth <= 800 ? true : false;
  images = [
    { src: "https://via.placeholder.com/60", alt: "Image 1", name: "Name 1" },
    { src: "https://via.placeholder.com/60", alt: "Image 2", name: "Name 2" },
    { src: "https://via.placeholder.com/60", alt: "Image 3", name: "Name 3" },
    { src: "https://via.placeholder.com/60", alt: "Image 4", name: "Name 4" },
    { src: "https://via.placeholder.com/60", alt: "Image 5", name: "Name 5" },
    { src: "https://via.placeholder.com/60", alt: "Image 6", name: "Name 6" },
    { src: "https://via.placeholder.com/60", alt: "Image 7", name: "Name 7" },
  ];
  @ViewChild("swiper", { static: false }) swiper: any;
  @ViewChild("homeSwiper", { static: false }) homeSwiper: SwiperComponent;
  slides = [
    { content: "Slide 1", customClass: "custom-slide-1" },
    { content: "Slide 2", customClass: "custom-slide-2" },
  ];
  bansCount: number;
  communitiesCount: number;
  streamers: { name: string; profileImage: string }[];
  private destroy = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams["code"]) {
      this.apiService.login(queryParams["code"]).subscribe(async (res) => {
        await this.userService.getUserInfo();
        if (res) {
          this.router.navigate([], {
            queryParams: { code: null },
            queryParamsHandling: "merge",
          });
        }
      });
    }
    this.getMainPageData();
  }

  ngAfterViewInit(): void {
    // const slides = Array.from(this.swiper.elementRef.nativeElement.children[0].children);
    // this.applyTransitionAndTransform(slides, [0, 4], 'scale(0.85)');
  }

  private getMainPageData() {
    this.apiService.getMainPageData().subscribe({
      next: (result) => {
        this.bansCount = result.bansCount;
        this.communitiesCount = result.communitiesCount;
        this.streamers = [...result.streamers, ...result.streamers];
        // setTimeout(() => {
        //   const slides = Array.from(this.swiper.elementRef.nativeElement.children[0].children);
        //   this.applyTransitionAndTransform(slides, [0, 4], 'scale(0.85)');
        // }, 200)
      },
      error: () => {
        this.bansCount = 21342;
        this.communitiesCount = 579;
      },
    });
  }

  slideChanged(event: any) {
    const slides = event[0].slides;
    const check = slides.length - event[0].realIndex;
    const indicesToScaleDown = this.getIndicesToScaleDown(check);
    this.applyTransitionAndTransform(slides, indicesToScaleDown, "scale(0.85)");
  }

  private applyTransitionAndTransform(
    slides: any,
    indices: number[],
    transformValue: string
  ) {
    slides.forEach((slide, i) => {
      slide.style.transition = "all .3s ease-in-out";
      slide.style.transform = indices.includes(i) ? transformValue : "scale(1)";
    });
  }

  private getIndicesToScaleDown(check: number): number[] {
    switch (check) {
      case 6:
        return [1, 5];
      case 7:
        return [0, 4];
      case 5:
        return [2, 6];
      default:
        return [];
    }
  }

  onSlideChange(event: any) {}

  ignoreWheelEvent = false;

  onWheel(event: WheelEvent) {
    event.preventDefault();
    if (this.ignoreWheelEvent) {
      return;
    }
    const delta = Math.sign(event.deltaY);
    if (delta > 0) {
      this.homeSwiper.swiperRef.slideNext(1000);
    } else if (delta < 0) {
      this.homeSwiper.swiperRef.slidePrev(1000);
    }
    this.ignoreWheelEvent = true;
    setTimeout(() => {
      this.ignoreWheelEvent = false;
    }, 1000);
  }
}
