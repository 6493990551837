<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
                    <div>
                        <a class="logo" routerLink="/">
                            <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" /> -->
                            <img style="margin: auto;" class="img-fluid for-dark" width="200" src="assets/images/logo/logo_dark.png"
                                alt="looginpage" />
                        </a>
                    </div>
                    <!-- <h2 class="text-center">로그인</h2> -->
          <div class="login-main my-5">
            <form class="theme-form" [formGroup]="loginForm">
              <h5 class="text-center">회원로그인</h5>
              <div class="d-flex my-2 align-items-center"> <app-feather-icons [icon]="'lock'"></app-feather-icons> <span
                  class="ms-2">보안접속</span></div>

              <div class="form-group">
                <label class="col-form-label">아이디</label>
                <input class="form-control" type="email" required="" placeholder=""
                  formControlName="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">Email is required</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">Invalid Email</div>
              </div>
              
              <div class="form-group">
                <label class="col-form-label">비밀번호</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span>
                </div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                  class="text text-danger mt-1">Password is required</div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label class="text-muted" for="checkbox1">Remember password</label>
                    </div>
                    <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()"
                    type="button"><span>로그인</span></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>