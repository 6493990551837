import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seachByKeyword'
})
export class SearchByKeywordPipe implements PipeTransform {

  transform(value: any[], keyword: string): any[] {
    return value.filter((item) => {
      for (const fieldValue of Object.values(item)) {
        if (typeof fieldValue === 'string' && fieldValue.includes(keyword)) {
          return true;
        }
      }
      return false;
    });
  }

}
