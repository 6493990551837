import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() selectedPageSize: number = 0;
  @Input() page: number = 0;
  @Input() total: number = 0;
  @Output() pageSizeChanged = new EventEmitter();
  constructor(private tableService: TableService) { }
  onPageSizeChange(): void {
    this.page = 1;
    this.tableService.setPageSize(this.selectedPageSize);
    this.pageSizeChanged.emit(this.selectedPageSize)
  }
}
